import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Avatar from '@organisms/Post/Avatar'

import { H4 } from '@atoms/Typography'

const Post = ({ data, defaultCoverImage }) => {
  const { title, excerpt, slug, author } = data.frontmatter
  const coverImage = data.frontmatter.coverImage || defaultCoverImage

  return (
    <article>
      <S.LinkContainer to={`/blog/${slug}/`}>
        <S.Container>
          <S.ImageContainer>
            <GatsbyImage
              image={coverImage.childImageSharp.gatsbyImageData}
              layout="fixed"
              alt={title}
            />
          </S.ImageContainer>
          <H4 mb="10">{title}</H4>
          {author && <Avatar author={author} />}
        </S.Container>
      </S.LinkContainer>
    </article>
  )
}

const S = {}

S.LinkContainer = styled(Link)`
  display: block;
  ${breakpoints({
    xs: css`
      margin-bottom: 40;
    `,
    md: css`
      margin-bottom: 60;
    `,
    xl: css`
      margin-bottom: 100;
    `,
  })};
`

S.Container = styled.div`
  ${breakpoints({
    xs: css`
      width: 100%;
    `,
  })};
`

S.ImageContainer = styled.divBox`
  ${breakpoints({
    xs: css`
      margin-bottom: 20;
    `,
    lg: css`
      margin-bottom: 30;
    `,
  })};
`

export default Post
