import React from 'react'

import { Row, Col } from '@atoms/Grid'
import Post from './Post'

const Posts = ({ data, defaultCoverImage, ...props }) => {
  return (
    <Row>
      {data.nodes.map((item) => (
        <Col col={{ xs: 1, md: 1 / 2, xl: 4 / 12 }} key={item.id}>
          <Post data={item} defaultCoverImage={defaultCoverImage} />
        </Col>
      ))}
    </Row>
  )
}

export default Posts
