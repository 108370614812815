import React from 'react'
import { Link } from 'gatsby'
import styled, { css, space } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'

import arrowLeft from '@assets/svg/arrow-left.svg'
import arrowRight from '@assets/svg/arrow-right.svg'

const getPreviousLink = (currentPage) => {
  if (currentPage <= 2) return '/blog/'

  return `/blog/page/${currentPage - 1}/`
}

const getNextLink = (currentPage, numPages) => {
  if (currentPage === numPages) return `/blog/page/${numPages}`

  return `/blog/page/${currentPage + 1}/`
}

const Pagination = ({ currentPage, numPages }) => {
  const previousLink = getPreviousLink(currentPage)
  const nextLink = getNextLink(currentPage, numPages)

  return (
    <S.Pagination>
      <S.Link to={previousLink} disabled={currentPage === 1}>
        <S.CircleWrapper mr={10}>
          <S.ArrowLeft />
        </S.CircleWrapper>
        Previous
      </S.Link>
      <S.Link to={nextLink} disabled={currentPage === numPages}>
        Next
        <S.CircleWrapper ml={10}>
          <S.ArrowRight />
        </S.CircleWrapper>
      </S.Link>
    </S.Pagination>
  )
}

const S = {}

S.Pagination = styled.div`
  display: flex;
  justify-content: space-between;
`

S.Link = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: ${th.fontWeight('bold')};
  cursor: pointer;
  line-height: 1;
  &:hover {
    --hover-grey: rgba(255, 255, 255, 0.1);
  }

  ${breakpoints({
    xs: css`
      font-size: 14;
    `,
    md: css`
      font-size: 19;
    `,
  })}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      color: ${th('colors.light')};
      opacity: 0.25;
    `};
`

S.CircleWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
  background: var(--hover-grey, transparent);

  ${space}
`

const arrowStyle = css`
  width: 27;
  height: 16;
`

S.ArrowLeft = styled(arrowLeft)`
  ${arrowStyle}
`

S.ArrowRight = styled(arrowRight)`
  ${arrowStyle}
`

export default Pagination
