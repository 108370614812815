import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import idgen from '@utils/idgen'
import { Container } from '@atoms/Grid'
import Layout from '@organisms/Layout'
import ExternalSection from '@organisms/Feed/ExternalSection'
import Pagination from '@organisms/Feed/Pagination'

const getTitle = (meta, currentPage, numPages) => {
  if (currentPage === 1) return meta.title

  let title = meta.titlePagination
  title = title.replace('currentPage', currentPage)
  title = title.replace('totalPages', numPages)

  return title
}

const getDescription = (meta, currentPage, numPages) => {
  if (currentPage === 1) return meta.description

  let description = meta.descriptionPagination
  description = description.replace('currentPage', currentPage)
  description = description.replace('totalPages', numPages)

  return description
}

const Feed = ({ data, pageContext }) => {
  const { meta, blocks } = data.blogPage.nodes[0].frontmatter
  const { posts } = data

  const currentPage = pageContext.currentPage
  const numPages = pageContext.numPages
  const title = getTitle(meta, currentPage, numPages)
  const description = getDescription(meta, currentPage, numPages)

  return (
    <Layout hamburgerMenu title={title} description={description} invertColor>
      <S.Main>
        <S.Container>
          {blocks.map((item) => {
            switch (item.type) {
              case 'externalSection':
                return (
                  <ExternalSection
                    title={item.title}
                    paragraphs={item.paragraphs}
                    defaultCoverImage={item.defaultCoverImage}
                    data={posts}
                    key={idgen()}
                  />
                )
              default:
                return null
            }
          })}
          <Pagination currentPage={currentPage} numPages={numPages} />
        </S.Container>
      </S.Main>
    </Layout>
  )
}

const S = {}

S.Main = styled.mainBox`
  background: ${th('colors.dark')};
  color: ${th('colors.light')};
  ${breakpoints({
    xs: css`
      padding-top: 160;
      padding-bottom: 111;
    `,
    lg: css`
      padding-top: 252;
      padding-bottom: 137;
    `,
  })}
`

S.Container = styled(Container)``

export default Feed

// Set here the ID of the home page.
export const pageQuery = graphql`
  query BlogPage($skip: Int!, $limit: Int!) {
    blogPage: allMarkdownRemark(
      filter: { frontmatter: { collection: { eq: "blog-template" } } }
    ) {
      nodes {
        frontmatter {
          meta {
            description
            descriptionPagination
            title
            titlePagination
            image {
              childImageSharp {
                gatsbyImageData(width: 500, layout: CONSTRAINED)
              }
            }
          }
          blocks {
            type
            title
            paragraphs
            section
            defaultCoverImage {
              childImageSharp {
                gatsbyImageData(width: 500, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
    posts: allMarkdownRemark(
      filter: { frontmatter: { collection: { eq: "blog" } } }
      sort: { order: DESC, fields: frontmatter___date }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          date_published: date(formatString: "YYYY-MM-DD")
          date_modified: date(formatString: "YYYY-MM-DD")
          excerpt
          slug
          coverImage {
            childImageSharp {
              gatsbyImageData(width: 387, layout: CONSTRAINED, aspectRatio: 1.5)
            }
          }
          ...AuthorFragment
        }
        timeToRead
      }
    }
  }
`
