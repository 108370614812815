const getLanguage = () => {
  if (typeof window !== 'undefined' && typeof navigator !== 'undefined') {
    return (
      navigator.userLanguage ||
      (navigator.languages &&
        navigator.languages.length &&
        navigator.languages[0]) ||
      navigator.language ||
      navigator.browserLanguage ||
      navigator.systemLanguage ||
      'en'
    )
  }
  return 'en'
}

export const format = (
  dateString,
  options = { year: 'numeric', month: 'short', day: 'numeric' }
) => {
  const formatter = new Intl.DateTimeFormat(getLanguage(), options)
  const date = new Date(dateString)
  return formatter.format(date)
}
