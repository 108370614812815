import React from 'react'
import styled, { css, x } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'

import { Container } from '@atoms/Grid'
import { H2, Text } from '@atoms/Typography'
import Posts from './Posts'

const ExternalSection = ({ title, defaultCoverImage, data, paragraphs }) => {
  return (
    <>
      <x.div textAlign={{ xs: 'left', md: 'center' }}>
        <H2 as="h1" mb="10">
          {title}
        </H2>
        <Text>{paragraphs}</Text>
      </x.div>
      <S.Container>
        <Posts data={data} defaultCoverImage={defaultCoverImage} />
      </S.Container>
    </>
  )
}

const S = {}

S.Container = styled(Container)`
  padding-left: 0;
  padding-right: 0;
  ${breakpoints({
    xs: css`
      padding-top: 30;
      padding-bottom: 60;
    `,
    md: css`
      padding-top: 100;
      padding-bottom: 111;
    `,
  })};
`

export default ExternalSection
