import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { format } from '@utils/date'

const Avatar = ({ author, datePublished, ...props }) => {
  const image = getImage(author.frontmatter.profileThumbnail)
  return (
    <S.AvatarContainer {...props}>
      <S.Avatar image={image} alt={author.frontmatter.title} />
      <S.Paragraph>{author.frontmatter.title}</S.Paragraph>
      {datePublished && <S.Paragraph>{format(datePublished)}</S.Paragraph>}
    </S.AvatarContainer>
  )
}

const S = {}

S.AvatarContainer = styled.divBox`
  display: flex;
  align-items: center;
`

S.Avatar = styled(GatsbyImage)`
  border-radius: 50%;
  height: 30px;
  width: 30px;
  ${breakpoints({
    xs: css`
      margin-right: 10;
    `,
    md: css`
      margin-right: 15;
      height: 30px;
      width: 30px;
    `,
  })}

  [data-main-image] {
    border-radius: 50%;
  }
`

S.Paragraph = styled.pBox`
  ${breakpoints({
    xs: css`
      font-size: 14;
      &:first-of-type {
        margin-right: 20;
      }
    `,
    md: css`
      font-size: 19;
    `,
  })}
`

export default Avatar
